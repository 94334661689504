export default function (instance) {
  return {
    username: instance.$requiredRule,
    medium: instance.$requiredRule,
    code: instance.$requiredRule,
    password: [
      instance.$requiredRule,
      {
        trigger: 'blur',
        min: 8,
        get message() {
          return instance.$t('validation-pass-min', { length: instance.$n(8) });
        },
      },
      {
        trigger: 'blur',
        max: 16,
        get message() {
          return instance.$t('validation-pass-max', { length: instance.$n(16) });
        },
      },
    ],
    confirmedPassword: [
      instance.$requiredRule,
      {
        trigger: 'blur',
        validator(_, value, callback) {
          if (value === instance.model.password) {
            return callback();
          }

          callback(instance.$t('validation-pass-mismatch'));
        },
      },
    ],
  };
}
