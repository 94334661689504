<template>
  <section>
    <el-form
      class="el-card"
      ref="form"
      :model="model"
      :disabled="loading"
      :rules="rules"
      @submit.native.prevent="submit"
    >
      <div class="el-card__header">
        <h1 class="text-center">
          {{ $t('password-reset') }}
        </h1>
      </div>

      <div class="el-card__body">
        <el-tabs
          v-model="tab"
          class="card-form-tabs"
          ref="tabs"
          stretch
        >
          <el-tab-pane
            :disabled="!!model.key"
            name="send-code"
            lazy
          >
            <template slot="label">
              <fai icon="envelope"/>
              {{ $t('send-code') }}
            </template>

            <el-form-item
              prop="username"
              :label="$t('username-l')"
            >
              <el-input
                prefix-icon="el-icon-user"
                v-model="model.username"
              />
            </el-form-item>

            <el-form-item
              prop="medium"
              :label="$t('verification-medium')"
            >
              <br>
              <el-radio
                label="EMAIL"
                v-model="model.medium"
              >
                {{ $t('email') }}
              </el-radio>
              <el-radio
                label="MOBILE"
                v-model="model.medium"
              >
                {{ $t('sms') }}
              </el-radio>
            </el-form-item>
          </el-tab-pane>

          <el-tab-pane
            :disabled="!model.id"
            name="reset"
            lazy
          >
            <template slot="label">
              <fai icon="key"/>
              {{ $t('password-reset') }}
            </template>

            <el-form-item
              prop="code"
              :label="$t('code')"
            >
              <el-input
                prefix-icon="el-icon-key"
                v-model="model.code"
              />
            </el-form-item>

            <el-form-item
              prop="password"
              :label="$t('password')"
            >
              <el-input
                prefix-icon="el-icon-key"
                v-model="model.password"
                type="password"
                show-password
              />
            </el-form-item>

            <el-form-item
              prop="confirmedPassword"
              :label="$t('confirm-pass')"
            >
              <el-input
                v-model="model.confirmedPassword"
                prefix-icon="el-icon-key"
                type="password"
                show-password
              />
            </el-form-item>
          </el-tab-pane>

          <br>
          <el-button
            class="button bg-gd-pm"
            size="big"
            native-type="submit"
            :icon="loading?'el-icon-loading':''"
            :disabled="loading"
          >
            <fai
              v-show="!loading"
              icon="paper-plane"
            />
            {{ $t('submit') }}
          </el-button>
        </el-tabs>
      </div>
    </el-form>
  </section>
</template>

<script>
import VERIFICATION from './graphql/verification.graphql';
import SEND_CODE from './graphql/send-code.graphql';
import rules from './rules';

export default {
  data() {
    return {
      model: {
        username: null,
        medium: 'EMAIL',
        password: null,
        confirmedPassword: null,
        code: null,
        id: null,
        key: null,
      },
      tab: 'send-code',
      loading: false,
    };
  },

  computed: {
    rules() {
      return rules(this);
    },
  },

  methods: {
    async submit() {
      try {
        await this.$refs.form.validate();
      } catch (e) {
        return;
      }

      this.errorMsg = null;

      // Show spinner
      this.loading = true;

      if (this.tab === 'send-code') {
        await this.sendCode();
      } else {
        await this.verification();
      }

      // Hide spinner
      this.loading = false;
    },

    async sendCode() {
      try {
        const { data: { change_reset_password: result } } = await this.$apollo.mutate({
          mutation: SEND_CODE,
          variables: {
            username: this.model.username,
            medium: this.model.medium,
          },
        });

        this.model.id = result.id;

        if (result.key) {
          this.model.key = result.key;
          localStorage.setItem('password_reset_key', result.key);
          this.tab = 'reset';
        } else if (localStorage.getItem('password_reset_key')) {
          this.model.key = localStorage.getItem('password_reset_key');
          this.tab = 'reset';
        } else {
          this.$message({
            type: 'error',
            message: this.$t('reset-no-key'),
            duration: 6000,
          });
        }
      } catch (e) {
        //
      }
    },

    async verification() {
      try {
        await this.$apollo.mutate({
          mutation: VERIFICATION,
          variables: {
            id: this.model.id,
            key: this.model.key,
            token: this.model.code,
            password: this.model.password,
          },
        });

        this.$message({
          type: 'success',
          message: this.$t('password-reset-success'),
        });

        localStorage.removeItem('password_reset_key');

        this.$router.push({
          name: 'login',
        });
      } catch (e) {
        //
      }
    },
  },

  created() {
    if (this.$store.state.auth.user) {
      this.$router.push({
        name: 'home',
      });
    }
  },
};
</script>

<style lang="scss" scoped>
@import "src/home/styles/var";

h1 {
  margin: 0;
}

.el-card {
  height: calc(100vh - #{ ($header-padding * 2) + $header-height } - 2px);
}

.el-card__body {
  padding: 0;
}

.button {
  width: 100%;
}

@media all and (min-width: $--md) {
  section {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    height: calc(100vh - #{ ($header-padding * 2) + $header-height });
    background-image: url("../../../assets/images/background.svg");
    background-repeat: no-repeat;
    background-size: cover;
  }

  .el-card {
    width: 400px;
    height: auto;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  }
}
</style>
